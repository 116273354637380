<template>
  <div  class="px-15px px-lg-25px">
    <div class="row gutters-10 justify-content-center">
      <div class="col-md-4 mx-auto mb-3">
        <div class="bg-grad-1 text-white rounded-lg overflow-hidden">
          <span class="size-30px rounded-circle mx-auto bg-soft-primary d-flex align-items-center justify-content-center mt-3">
              <i class="las la-upload la-2x" style="color: #007bff"></i>
          </span>
          <div class="px-3 pt-3 pb-3">
            <div class="h4 fw-700 text-center">{{page.totalRecord}}</div>
            <div class="opacity-50 text-center">{{ $t('zong-ding-dan') }}</div>
          </div>
        </div>
      </div>
      <div class="col-md-4 mx-auto mb-3">
        <div class="bg-grad-1 text-white rounded-lg overflow-hidden">
          <span class="size-30px rounded-circle mx-auto bg-soft-primary d-flex align-items-center justify-content-center mt-3">
              <i class="las la-upload la-2x" style="color: #007bff"></i>
          </span>
          <div class="px-3 pt-3 pb-3">
            <div class="h4 fw-700 text-center">${{turnoverMoney}}</div>
            <div class="opacity-50 text-center">{{ $t('zong-ying-ye-e') }}</div>
          </div>
        </div>
      </div>
      <div class="col-md-4 mx-auto mb-3">
        <div class="bg-grad-1 text-white rounded-lg overflow-hidden">
          <span class="size-30px rounded-circle mx-auto bg-soft-primary d-flex align-items-center justify-content-center mt-3">
              <i class="las la-upload la-2x" style="color: #007bff"></i>
          </span>
          <div class="px-3 pt-3 pb-3">
            <div class="h4 fw-700 text-center">${{totalProfit}}</div>
            <div class="opacity-50 text-center">{{ $t('zong-li-run') }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">

       <form >
        <div class="card-header row gutters-5">
          <div class="col text-center text-md-left">
            <h5 class="mb-md-0 h6">{{ $t('ding-dan') }}</h5>
          </div>
          <!-- <div class="col-md-3 ml-auto">
            <a-select v-model="form.payStatus" class="form-control form-control-sm aiz-selectpicker mb-2 mb-md-0" >
              <a-select-option value="">付款状态</a-select-option>
              <a-select-option :value="item.value" v-for="(item, i) in statusList1" :key="i">{{item.label}}</a-select-option>
            </a-select>
          </div> -->

          <div class="col-md-3 ml-auto">
            <a-select v-model="form.status" class="form-control form-control-sm aiz-selectpicker mb-2 mb-md-0"  @change="init">
              <a-select-option :value="item.value" v-for="(item, i) in statusList2" :key="i">{{item.label}}</a-select-option>
            </a-select>
          </div>
          <div class="col-md-3">
            <div class="from-group mb-0">
              <input type="text" class="form-control" v-model="form.id" @blur="init"  :placeholder="$t('qing-shu-ru-ding-dan-bian-hao')">
            </div>
          </div>
        </div>
      </form>

      <div class="card-body p-3">
        <table class="table mb-0"  :class="tableData.length == 0 ? ' footable aiz-table' : ''">
          <thead>
            <tr>
              <th>#</th>
              <th data-breakpoints="md">{{ $t('ding-dan-bian-hao') }} </th>
              <th >{{ $t('jiao-huo-lei-xing') }}</th>
              <th data-breakpoints="md">{{ $t('zhi-fu-lei-xing') }}</th>
              <th data-breakpoints="md">{{ $t('shou-huo-di-zhi') }}</th>
              <th data-breakpoints="md">{{ $t('ding-dan-lei-xing') }}</th>
              <th>{{ $t('orderTime') }}</th>
<!--              <th>{{ $t('deliveryTime') }}</th>-->
              <th>{{ $t('zong-li-run') }}</th>
              <th>{{ $t('Delivery-amount') }}</th>
              <th>{{ $t('zong-e') }}</th>
              <th data-breakpoints="md">{{ $t('zhi-fu-zhuang-tai') }}</th>
            
              <th data-breakpoints="md">{{ $t('ding-dan-zhuang-tai') }}</th>
              <th data-breakpoints="md">{{ $t('Fund Status') }}</th>
              <th data-breakpoints="md" class="text-right">{{ $t('cao-zuo') }}</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(item, i) in tableData" :key="i">
              <td>{{ i + 1 }}</td>
              <td>   
                <a @click="toDetail(item)">{{item.OrderNo}}</a>
              </td>
              <td> {{ $t('song-huo-shang-men') }} </td>
              <td v-show="false"> {{item.PayType == 1 ? $t('huo-dao-fu-kuan') : (item.PayType == 2 ? 'USDT' : $t('xian-xia-zhi-fu'))}} </td>
              <td> USDT </td>
              <td>
                <div>
                  <span>{{(item.Address.FullName)}}  </span>
                  <br>
                  <span> {{(item.Address.Address)}} {{(item.Address.City)}} </span>
                </div>
              </td>
              <td>
                <span class="badge badge-inline badge-success" v-if="item.OrderType == 1">{{ $t('pu-tong-ding-dan') }}</span>
                <span class="badge badge-inline badge-danger" v-if="item.OrderType == 2">{{ $t('jia-ji-ding-dan') }}</span>
              </td>

              <td>{{ item.CreatedAt }}</td>
<!--              <td v-if="item.Logistics !== undefined && item.Logistics.LogisticsRoute.length > 0">{{ item.Logistics.LogisticsRoute[0].CreatedAt }}</td>-->
<!--              <td v-else></td>-->
              <td>${{ parseFloat(item.Profit).toFixed(2) }}</td>
              <td>${{ parseFloat(item.PickupAmount).toFixed(2) }}</td>
              <td>${{ parseFloat(item.TotalAmount).toFixed(2) }}</td>
              <td>
                <span class="badge badge-inline badge-success" v-if="item.PayStatus == 1">{{ $t('yi-zhi-fu') }}</span>
                <span class="badge badge-inline badge-success" v-if="item.PayStatus == 2">{{ $t('wei-zhi-fu') }}</span>
                <span class="badge badge-inline badge-danger" v-if="item.PayStatus == 3">{{ $t('yi-tui-kuan') }}</span>
              </td>
              <td>
                <span class="badge badge-inline badge-info" v-if="item.Status == 0">{{ $t('chu-li-zhong') }}</span>
                <span class="badge badge-inline badge-info" v-if="item.Status == 1">{{ $t('yi-ti-huo') }}</span>
                <span class="badge badge-inline badge-info" v-if="item.Status == 2">{{ $t('zai-tu-zhong') }}</span>
                <span class="badge badge-inline badge-success" v-if="item.Status == 3">{{ $t('yi-qian-shou') }}</span>
                <span class="badge badge-inline badge-danger" v-if="item.Status == 4">{{ $t('Illegal order') }}</span>
                <span class="badge badge-inline badge-danger" v-if="item.Status == 5">{{ $t('yi-qu-xiao') }}</span>
              </td>
              <td>
                <span v-if="item.Status > 0 && item.Status < 4">
                  <span class="badge badge-inline badge-success" v-if="item.ReleaseStatus == 1">{{ $t('Released') }}</span>
                  <span class="badge badge-inline badge-info" v-else>{{ $t('Frozen') }}</span>
                </span>

              </td>
              <td class="text-right">
                <a class="btn btn-soft-info btn-icon btn-circle btn-sm"  :title="$t('ding-dan-xiang-qing')" @click="toDetail(item)">
<!--                  <i class="las la-trash"></i>-->
                  <van-icon name="eye" />
                </a>
              </td>
            </tr>
             <tr class="footable-empty" v-if="tableData.length == 0"><td colspan="9">{{ $t('shi-mo-du-mei-zhao-dao') }}</td></tr>
          </tbody>
        </table>
        <div class="aiz-pagination" v-if="page.total > 1">
          <pageBar :totalPage="page.total" :page="page.page" @change="changePage"></pageBar>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import pageBar from '@/components/page'
import { shopOrderList, orderPay, turnover } from '@/api/seller'
import { resetPrice } from '@/utils/common'
export default {
  components: {
    pageBar
  },
  data() {
    return {
      showPopup: false,
      form: {
        status: '',
        id: ''
      },
      statusList1: [],
      statusList2: [
        {
          value: '',
          label: this.$t('zheng-chang-ding-dan')
        },
        {
          value: '2',
          label: this.$t('dong-jie-ding-dan')
        },
      ],
      hasPackage: true,
      packageUrl: require('../../../assets/imgs/package.png'),
      tableData: [],
      page: {
        page: 1,
        limit: 10,
        total: 1,
        totalRecord: 0
      },
      turnoverMoney: '0.00',
      totalProfit: '0.00',
    }
  },
  mounted() {
    this.init()
    this.initTurnover()
  },
  methods: {
    resetPrice,
    initTurnover() {
      turnover().then(res => {
        this.turnoverMoney = parseFloat(res.data.Turnover).toFixed(2)
        this.totalProfit = parseFloat(res.data.TotalProfit).toFixed(2)
      })
    },
    init() {
      let form = new FormData()
      form.append('current', this.page.page)
      form.append('pageSize', this.page.limit)
      form.append('MerchantPayStatus', this.form.status)
      form.append('ID', this.form.id)
      shopOrderList(form).then(res => {
        this.tableData = res.data.Items.map(v => {
          v.Address = JSON.parse(v.Address)
          v.Address.FullName = this.resetUserInfo(v.Address.FullName)
          v.Address.Address = this.resetUserInfo(v.Address.Address)
          v.Address.City = this.resetUserInfo(v.Address.City)
          return v
        })
        this.page.total = res.data.Pagination.totalPage
        this.page.totalRecord = res.data.Pagination.totalRecords
      })
    },
    changePage(page) {
      this.page.page = page
      this.init()
    },
    openModal(data) {
      this.showPopup = true
    },
    toDetail(data) {
      sessionStorage.setItem('orderData', JSON.stringify(data))
      this.$router.push({
        name: 'orderDetail',
        query: {
          id: data.ID
        }
      })
    },
    resetUserInfo(info) {
			if (!info) {
				return ''
			}
      info += ''
      let newInfo = ''
      if (info.length <= 4) {
        newInfo = new Array(info.length).join('*') + info.substring(info.length - 1)
      } else {
        newInfo = info.substring(0, 2) + new Array(info.length - 3).join('*') + info.substring(info.length - 2)
      }
      return newInfo
    },
  }
}
</script>